export default function IconfTent() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
            <rect x="0.5" y="0.5" width="31" height="31" rx="3.5" stroke="black" />
            <rect opacity="0.01" x="4" y="4" width="24" height="24" fill="black" />
            <path d="M20 8.99994L15.0667 19.7454V22.2666" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6 18.7998L15.0667 19.7453" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M20.3999 10L25.9999 18.5995" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.8989 8.94488C11.6583 9.31488 13.0475 9.49988 15.0667 9.49988C17.0858 9.49988 18.8076 9.22599 20.2322 8.67822L26 18.5994V20.9958L15.0667 22.2666L6 21.3211V18.7999L10.8989 8.94488Z"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="M15.0667 22.2668L26 20.9961V18.5996" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M20.3999 10V21.6083" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path
                d="M20.3999 10.4165C20.9542 13.4083 21.4973 15.4027 22.0292 16.3997C22.5611 17.3967 23.2917 18.3013 24.2212 19.1135L20.3252 21.608"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
